import React from "react"
import Layout from "../components/layout"
import { graphql, useStaticQuery } from "gatsby"

export default () => {
  const {
    file: {
      childMarkdownRemark: { html, frontmatter },
    },
  } = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "pages/terms.md" }) {
        childMarkdownRemark {
          html
          frontmatter {
            title
            meta {
              title
              description
            }
          }
        }
      }
    }
  `)

  return (
    <Layout meta={frontmatter.meta}>
      <section>
        <div className="container">
          <div className="row">
            <div className="sub-page-heading">
              <h1>{frontmatter.title}</h1>
            </div>
          </div>
        </div>
      </section>
      <section className="container">
        <div className="row" dangerouslySetInnerHTML={{ __html: html }} />
      </section>
    </Layout>
  )
}
